export const lineName = {
  LINE_1: "1호선",
  LINE_2: "2호선",
  LINE_3: "3호선",
  LINE_4: "4호선",
  LINE_5: "5호선",
  LINE_6: "6호선",
  LINE_7: "7호선",
  LINE_8: "8호선",
  LINE_9: "9호선",
  GYEONGGANG: "경강선",
  GYEONGUI: "경의선",
  GYEONGCHUN: "경춘선",
  AIRPORT: "공항철도",
  GIMPOGOLD: "김포골드",
  BUNDANG: "분당선",
  SEOHAE: "서해선",
  SUIIN: "수인선",
  SINBUNDANG: "신분당선",
  YONGINGYEONGJEON: "용인경전철",
  UIISHINSEOLGYEONGJEON: "우이신설경전철",
  UIJEONBUGYEONGJEON: "의정부경전철",
  INCHEON: "인천선",
  INCHEON2: "인천2호선",
};
